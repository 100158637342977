import React from 'react';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MainreIcon from '../../assets/m1.png';
import NewsIcon from '../../assets/m3.png';
import PromoIcon from '../../assets/m2.png';
import ProfileIcon from '../../assets/m4.png';
import Paper from '@mui/material/Paper';
import './Menu.css';

const Menu = () => {
    const [value, setValue] = React.useState(0);
    return (
        <div>
            <Paper sx={{ position: 'fixed', bottom: 15, left: 0, right: 0, marginLeft: 2, marginRight: 2, zIndex: 5 }} elevation={10}>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    showLabels
                >
                    <BottomNavigationAction 
                        label="Главная" 
                        icon={<img src={MainreIcon} alt="Главная" style={{ width: 25, height: 25 }}/>}
                        component={Link} 
                        to="/"
                    />
                    <BottomNavigationAction 
                        label="Top 10" 
                        icon={<img src={NewsIcon} alt="Top 10" style={{ width: 25, height: 25 }}/>} 
                        component={Link} 
                        to="/news" 
                    />
                    <BottomNavigationAction 
                        label="Подбор" 
                        icon={<img src={PromoIcon} alt="Подбор" style={{ width: 25, height: 25 }}/>}
                        component={Link} 
                        to="/promotions"
                    />
                    <BottomNavigationAction 
                        label="Комадна" 
                        icon={<img src={ProfileIcon} alt="Комадна" style={{ width: 25, height: 25 }}/>}
                        component={Link} 
                        to="/profile"
                    />
                </BottomNavigation>     
            </Paper>
        </div>
    );
};

export default Menu;