import React, { useState, useEffect, memo } from 'react';
import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PoolIcon from '@mui/icons-material/Pool';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Slider from 'react-slick'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './NewsPage.css'; 
import RPimg from '../../assets/load.svg';

// Компонент для иконки и текста
const IconText = ({ icon: Icon, text }) => (
  <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', lineHeight: '1.4' }}>
    <Icon sx={{ marginRight: '8px', color: '#ec6608', height: '20px' }} />
    {text}
  </Typography>
);

// Компонент одного объекта
const ObjectCard = memo(({ object }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false); // Состояние для загрузки изображений
  const images = object.images.split(',').filter(image => image); // Разбиваем строку с изображениями на массив

  // Отслеживаем, когда все изображения загружены
  useEffect(() => {
    let loadedCount = 0;
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        loadedCount += 1;
        if (loadedCount === images.length) {
          setImagesLoaded(true); // Все изображения загружены
        }
      };
      img.onerror = () => {
        loadedCount += 1;
        if (loadedCount === images.length) {
          setImagesLoaded(true); // Если изображение не загружается, тоже скрываем лоадер
        }
      };
    });
  }, [images]);

  return (
    <div className="slider-wrapper">
      <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1} arrows={false} dotsClass="slick-dots custom-dots">
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Image ${index + 1} for ${object.name}`} style={{ width: '100%', height: '230px', objectFit: 'cover', borderRadius: '15px' }} />
          </div>
        ))}
      </Slider>
      <Box p={2}>
        <Typography variant="h5" sx={{ fontSize: '1.3rem', marginTop: '-20px', lineHeight: '1.1' }}>
          <Link href={object.url} target="_blank"  underline="hover" color="inherit">{object.name}</Link>
        </Typography>
        <Box mt={2} sx={{ color: '#737373' }}>
          <IconText icon={LocationOnIcon} text={object.location} />
          <IconText icon={HomeIcon} text={`Комнат: ${object.rooms}`} />
          <IconText icon={SquareFootIcon} text={`Площадь: ${object.area} м²`} />
          <IconText icon={ApartmentIcon} text={`Этаж: ${object.floor}`} />
          <IconText icon={PoolIcon} text={`До моря: ${object.distanceToSea} м`} />
          <IconText icon={AccountBalanceIcon} text={`До центра: ${object.distanceToCenter} м`} />
          <IconText icon={CalendarMonthIcon} text={`Год постройки: ${Math.floor(object.year)}`} />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5" mt={2} sx={{ color: '#2998D1', fontWeight: '600' }}>€ {new Intl.NumberFormat('ru-RU').format(Math.floor(object.price))}</Typography>
            <Typography variant="body1" mt={2} sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>ID: {Math.floor(object.code)}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
});

const FullscreenLoader = ({ progress }) => (
  <Box sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1
  }}>
    
    <img src={RPimg} alt="Loading..." className="pulse" style={{ width: '200px', marginBottom: '25px' }} />
    <Typography variant="h4" color="textSecondary" style={{ textAlign: 'center',marginBottom: '20px',fontSize: '25px',fontWeight: '500' }}>Подбираем горячие предложения</Typography>
    <Typography variant="h4" color="textSecondary">{progress}%</Typography>
  </Box>
);

const NewsPage = () => {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // Состояние для отслеживания прогресса

  useEffect(() => {
    // Загрузка данных с API
    const fetchData = async () => {
      try {
        const response = await fetch('https://rp.z-code.info/object-data.php'); // Убедитесь, что URL правильный
        const data = await response.json();
        setObjects(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      // Отслеживаем загрузку изображений для всех объектов
      let totalImages = 0;
      let loadedImages = 0;

      objects.forEach((object) => {
        const images = object.images.split(',').filter(image => image);
        totalImages += images.length;

        images.forEach((imageSrc) => {
          const img = new Image();
          img.src = imageSrc;
          img.onload = () => {
            loadedImages += 1;
            setProgress(Math.floor((loadedImages / totalImages) * 100));
          };
          img.onerror = () => {
            loadedImages += 1;
            setProgress(Math.floor((loadedImages / totalImages) * 100));
          };
        });
      });
    }
  }, [loading, objects]);

  if (loading || progress < 100) {
    return <FullscreenLoader progress={progress} />;
  }

  return (
    <Box sx={{ mb:6 }}>
      <Box component="section" sx={{ p: 2, pb: 0 }}>
        <Typography component="div" variant="h5" sx={{ fontSize: '1.3rem', mb: 2, mt: 1  }}>Горячие предложения TOP-10</Typography>
        <Typography component="div" variant="body2" sx={{ p:2 ,color: '#737373',borderRadius: '15px', border: '1px solid #ccc', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>Мы подготовили для своих клиентов обширный список специальных предложений: турецкая недвижимость со скидками! Не пропустите и Вы свою удачу – успейте купить недвижимость по сниженной цене!
        </Typography>
      </Box>
      <div className="slider-container">
        {objects.map((object) => (
          <ObjectCard key={object.id} object={object} /> // Используйте ID объекта как ключ
        ))}
      </div>
    </Box>
  );
};

export default NewsPage;
