import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Menu from "./Components/Menu/Menu";
import HomePage from './Pages/HomePage/HomePage';
import NewsPage from './Pages/NewsPage/NewsPage';
import PromoPage from './Pages/PromoPage/PromoPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import './App.css';
import Header from './Components/Header/Header';
import { useDispatch } from 'react-redux';
import StartImg from './assets/start.jpg';
import Slide1 from './assets/slide1.jpg'; // Добавьте свои изображения
import Slide2 from './assets/slide2.jpg';
import Slide3 from './assets/slide3.jpg';

const tg = window.Telegram.WebApp;

function App() {

  const dispatch = useDispatch();
  const [telegramLoaded, setTelegramLoaded] = useState(false);
  const [showSlider, setShowSlider] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const sliderShown = localStorage.getItem('sliderShown');
    
    if (!sliderShown) {
      setShowSlider(true); // Показываем слайдер, если он не был показан ранее
    }
    tg.disableVerticalSwipes();
    tg.expand();
    tg.enableClosingConfirmation();
    const userId = tg.initDataUnsafe.user?.id || null;
    const userName = tg.initDataUnsafe.user?.username || null;

    dispatch({ type: 'SET_USER_DATA', payload: { userId, userName } });
    
    // setTimeout(() => {
    //   setTelegramLoaded(true);
    // }, 2000);

    tg.ready();
  }, [dispatch]);

  useEffect(() => {
    if (showSlider) {
      // Инициализация MainButton
      tg.MainButton.setText(currentSlide === slides.length - 1 ? "Закрыть" : "Далее");
      tg.MainButton.show();

      tg.MainButton.onClick(handleNextSlide); // Обработка события нажатия

      return () => {
        tg.MainButton.offClick(handleNextSlide); // Убираем обработчик при размонтировании
      };
    } else {
      tg.MainButton.hide();
    }
  }, [currentSlide, showSlider]);

  const onClose = () => {
    tg.close();
  };

  const slides = [Slide1, Slide2, Slide3]; // массив слайдов

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      localStorage.setItem('sliderShown', 'true'); // Устанавливаем, что слайдер был показан
      setShowSlider(false); // Закрываем слайдер
      tg.MainButton.hide(); // Скрываем кнопку после завершения слайдера
    }
  };

  // if (!telegramLoaded) {
  //   return (
  //     <div className="loading-screen">
  //       <img src={StartImg} alt="Loading..." />
  //     </div>
  //   );
  // }

  if (showSlider) {
    return (
      <div className="slider-screen">
        <img  className="sliderImg" src={slides[currentSlide]} alt={`Slide ${currentSlide + 1}`} />
        {/* Кнопка отображается через Telegram WebApp MainButton, так что здесь ничего рендерить не нужно */}
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/promotions" element={<PromoPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Menu />
      </div>
    </Router>
  );
}

export default App;
