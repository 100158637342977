import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './HomePage.css';
import Arrow from '../../assets/arl.svg'; // стрелка
import inIco from '../../assets/in.png';
import ytIco from '../../assets/yt.png';
import tgIco from '../../assets/tg.png';
import stIco from '../../assets/st.svg';

// Импорт картинок стран
import trFlag from '../../assets/tr.svg';
import aerFlag from '../../assets/ae.svg';
import thrFlag from '../../assets/th.svg';
import idrFlag from '../../assets/id.svg';
import cyrFlag from '../../assets/cy.svg';
import trBg from '../../assets/tr.jpg';
import dbBg from '../../assets/db.jpg';
import tlBg from '../../assets/tl.jpg';
import blBg from '../../assets/bl.jpg';
import kpBg from '../../assets/kp.jpg';

const countries = [
  { name: 'Турция', flag: trFlag, background: trBg, link: 'https://restproperty.ru/turkey/' },
  { name: 'ОАЭ', flag: aerFlag, background: dbBg, link: 'https://restproperty.ru/dubai/' },
  { name: 'Таиланд', flag: thrFlag, background: tlBg, link: 'https://restproperty.ru/thailand/' },
  { name: 'Бали', flag: idrFlag, background: blBg, link: 'https://restproperty.ru/bali/' },
  { name: 'Кипр', flag: cyrFlag, background: kpBg, link: 'https://restproperty.ru/kipr/' },
];

// Компонент для ссылки на социальную сеть
const SocialLink = ({ href, icon, text, gradient }) => (
  <Grid item>
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          background: gradient,
          borderRadius: '15px',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          pl: 1,
          pr: 1,
        }}
      >
        <img
          src={icon}
          alt={`${text} icon`}
          style={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            objectFit: 'cover',
            marginRight: '10px',
          }}
        />
        <Typography variant="body2" sx={{ color: '#fff'}}>
          {text}
        </Typography>
      </Box>
    </a>
  </Grid>
);

// Компонент для отображения страны
const CountryItem = ({ name, flag, background, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 1,
        pl: 2,
        pr: 2,
        border: '1px solid #ddd',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box display="flex" alignItems="center"  sx={{ margin: '0' }}>
        <img
          src={flag}
          alt={`${name} flag`}
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            objectFit: 'cover',
            marginRight: '10px',
          }}
        />
        <Typography variant="body1" sx={{ color: '#fff',fontWeight: '500'  }}>
          {name}
        </Typography>
      </Box>
      <img src={Arrow} alt="Arrow" style={{ width: '20px' }} />
    </Box>
  </a>
);

const HomePage = () => {
  return (
    <Box component="section" sx={{ p: 2, mb:6 }}>
      <Typography component="div" variant="h5" sx={{ fontSize: '1.3rem', mb: 2, mt: 1 }}>
        Каталог недвижимости
      </Typography>

      <Grid container spacing={1}>
        {/* Турция идет в один ряд */}
        <Grid item xs={12}>
          <CountryItem {...countries[0]} />
        </Grid>

        {/* Остальные страны идут по две в ряд */}
        {countries.slice(1).map((country, index) => (
          <Grid item xs={6} sm={6} key={index}>
            <CountryItem {...country} />
          </Grid>
        ))}
      </Grid>

      <Typography component="div" variant="h5" sx={{ fontSize: '1.3rem', mb: 2, mt: 3 }}>
        О нашей компании
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={1}>
            <SocialLink
              href="https://www.instagram.com/restproperty.ru"
              icon={inIco}
              text="Следите за нами в Instagram!"
              gradient="linear-gradient(.2300019275turn, #9a58fa 0, #ff55ef 21%, #ff4f82 55%, #ffdf47 100%)"
            />
            <SocialLink
              href="https://www.youtube.com/c/restpropertyru"
              icon={ytIco}
              text="Наш канал на YouTube"
              gradient="linear-gradient(0turn, #ac2a2a 0, #f24e4e 100%)"
            />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column" spacing={1}>
            <SocialLink
              href="https://t.me/restpropertyru"
              icon={tgIco}
              text="Полезная информация"
              gradient="linear-gradient(0turn, #456bce 0, #0cb1e6 100%)"
            />
            <SocialLink
              href="https://restproperty.ru/"
              icon={stIco}
              text="Сайт компании RestProperty"
              gradient="linear-gradient(0turn, #ec6608 0, #f7a266 100%)"
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography component="div" variant="body2" sx={{ backgroundColor: '#f5f7fe',mb: 4, mt: 3, p:2 ,color: '#737373',borderRadius: '15px', border: '1px solid #ccc', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
        Международное лицензированное агентство недвижимости. Работая на рынке недвижимости более 20 лет, компания RestProperty наладила тесные связи с множеством турецких застройщиков. Кроме того, мы самостоятельно осуществляем строительство. Это позволило нам сформировать огромную базу объектов жилой недвижимости. Обратившись к нам, вы сделаете правильный выбор! Специалисты компании RestProperty великолепно ориентируются на рынке недвижимости. Вы получите полную исчерпывающую информацию по всем вопросам касающимся жилья в Турции. Ваш выбор будет взвешенным!
      </Typography>
    </Box>
  );
};

export default HomePage;
