import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Typography,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './PromoPage.css';

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#ccc', // Цвет линии между шагами
    borderWidth: 2,
  },
}));
// Кастомный чекбокс с оранжевой галочкой
const CustomCheckbox = styled(Checkbox)({
  color: '#ccc', // Цвет по умолчанию
  '&.Mui-checked': {
    color: '#ec6608', // Цвет при выборе
  },
});

// Кастомная радиокнопка с оранжевым кружком
const CustomRadio = styled(Radio)({
  color: '#ccc', // Цвет по умолчанию
  '&.Mui-checked': {
    color: '#ec6608', // Цвет при выборе
  },
});

const CustomStepLabel = styled(StepLabel)(({ theme, active, completed }) => ({
  '& .MuiStepLabel-label': {
    color: active || completed ? '#ec6608' : '#ccc', // Цвет текста
    fontWeight: active ? 'bold' : 'normal',
  },
  '& .MuiStepIcon-root': {
    color: active || completed ? '#ec6608' : '#ccc', // Цвет иконки
  },
  '& .MuiStepIcon-text': {
    fill: '#fff', // Цвет текста внутри иконки (например, номера шагов)
  },
  '& .Mui-completed': {
    color: '#22b222', // Цвет галочки для завершенных шагов
  },
  '& .Mui-active': {
    color: '#ec6608!important', // Цвет галочки для завершенных шагов
  },
}));

const PromoPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({
    propertyType: '',
    purchaseTime: '',
    interests: [],
    budget: '',
  });

  const handleNext = () => {
    if (activeStep === 3) {
      submitAnswers();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleRadioChange = (field) => (event) => {
    setAnswers({ ...answers, [field]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const updatedInterests = answers.interests.includes(value)
      ? answers.interests.filter((item) => item !== value)
      : [...answers.interests, value];
    setAnswers({ ...answers, interests: updatedInterests });
  };

  const submitAnswers = async () => {
    setLoading(true);
    const payload = {
      propertyType: answers.propertyType || '-',
      purchaseTime: answers.purchaseTime || '-',
      interests: answers.interests.join(', ') || '-',
      budget: answers.budget || '-',
    };

    try {
      const response = await fetch('https://bot.zimstyle.com/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Ответы успешно отправлены!');
        setActiveStep(4);
      } else {
        console.error('Ошибка при отправке данных:', response.statusText);
      }
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RadioGroup value={answers.propertyType} onChange={handleRadioChange('propertyType')}>
            <FormControlLabel value="1+1" control={<CustomRadio />} label="1+1" />
            <FormControlLabel value="2+1" control={<CustomRadio />} label="2+1" />
            <FormControlLabel value="3+1" control={<CustomRadio />} label="3+1" />
            <FormControlLabel value="villa" control={<CustomRadio />} label="Вилла" />
            <FormControlLabel value="other" control={<CustomRadio />} label="Другое" />
          </RadioGroup>
        );
      case 1:
        return (
          <RadioGroup value={answers.purchaseTime} onChange={handleRadioChange('purchaseTime')}>
            <FormControlLabel value="coupleMonths" control={<CustomRadio />} label="В ближайшие пару месяцев" />
            <FormControlLabel value="withinYear" control={<CustomRadio />} label="В течение года" />
            <FormControlLabel value="undecided" control={<CustomRadio />} label="Пока не решил(а)" />
          </RadioGroup>
        );
      case 2:
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <CustomCheckbox
                  value="remotePurchase"
                  checked={answers.interests.includes('remotePurchase')}
                  onChange={handleCheckboxChange}
                />
              }
              label="Удаленная покупка недвижимости"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  value="installments"
                  checked={answers.interests.includes('installments')}
                  onChange={handleCheckboxChange}
                />
              }
              label="Подбор рассрочки"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  value="legalSupport"
                  checked={answers.interests.includes('legalSupport')}
                  onChange={handleCheckboxChange}
                />
              }
              label="Юридическое сопровождение"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  value="propertyManagement"
                  checked={answers.interests.includes('propertyManagement')}
                  onChange={handleCheckboxChange}
                />
              }
              label="Управление недвижимостью"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  value="other"
                  checked={answers.interests.includes('other')}
                  onChange={handleCheckboxChange}
                />
              }
              label="Другое"
            />
          </FormGroup>
        );
      case 3:
        return (
          <RadioGroup value={answers.budget} onChange={handleRadioChange('budget')} >
            <FormControlLabel value="under100k" control={<CustomRadio />} label="До $100 000" />
            <FormControlLabel value="100kTo200k" control={<CustomRadio />} label="$100 000 - $200 000" />
            <FormControlLabel value="200kTo500k" control={<CustomRadio />} label="$200 000 - $500 000" />
            <FormControlLabel value="notDecided" control={<CustomRadio />} label="Не определились" />
          </RadioGroup>
        );
      default:
        return null;
    }
  };

  return (
    <Box component="section" sx={{ p: 2, mb:6 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
        sx={{ pt: 2 }}
      >
        {[...Array(4)].map((_, index) => (
          <Step key={index}>
            <CustomStepLabel
              active={activeStep === index}
              completed={index < activeStep}
            />
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>
        <CardContent sx={{ backgroundColor: '#f5f7fe',borderRadius: '15px', border: '1px solid #ccc', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',}}>
          {activeStep === 4 ? (
            <Box textAlign="center">
              <Typography>Спасибо за ваши ответы!</Typography>
            </Box>
          ) : loading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1.3rem', lineHeight: '1.1', pb:2,pt:2 }}>
                {activeStep === 0
                  ? 'Какой тип недвижимости вы рассматриваете к покупке?'
                  : activeStep === 1
                  ? 'Когда вы планируете приобрести недвижимость?'
                  : activeStep === 2
                  ? 'Выберите, что вас интересует:'
                  : 'Укажите ваш бюджет:'}
              </Typography>
              {renderStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ borderRadius: '15px', color: '#ec6608' }}>
                  Назад
                </Button>
                <Button
                    sx={{ borderRadius: '15px', backgroundColor: '#ec6608', boxShadow: 'none' }}
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    (activeStep === 0 && !answers.propertyType) ||
                    (activeStep === 1 && !answers.purchaseTime) ||
                    (activeStep === 2 && answers.interests.length === 0) ||
                    (activeStep === 3 && !answers.budget)
                  }
                >
                  {activeStep === 3 ? 'Отправить' : 'Далее'}
                </Button>
              </Box>
            </>
          )}
        </CardContent>
      </Box>
    </Box>
  );
};

export default PromoPage;
