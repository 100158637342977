import React, { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import wp from '../../assets/wp.svg';
import tg from '../../assets/tg.svg';
import vb from '../../assets/vb.svg';
import call from '../../assets/call.svg';
import sms from '../../assets/sms.svg';
import employees from './team.json';

// Компонент для отображения контактной информации
const ContactInfo = ({ icon, text }) => (
  <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary', display: 'flex' }}>
    <img src={icon} alt="Logo" style={{ width: '16px', height: 'auto' }} />
    <Box sx={{ pl: 1 }}>{text}</Box>
  </Typography>
);

// Компонент для карточки сотрудника
const EmployeeCard = memo(({ employee }) => (
  <Card sx={{ backgroundColor: '#f5f7fe', display: 'flex', mb: 2,borderRadius: '15px', border: '1px solid #ccc', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',}}>
    <CardMedia
      component="img"
      sx={{ width: 110 }}
      image={employee.image}
      alt={employee.name}
    />
    <Box sx={{ flex: '1 0 auto', pb: 0, pt: 2, pl: 2, pr: 2 }}>
      <Typography component="div" variant="h5" sx={{ fontSize: '1.2rem' }}>
        {employee.name}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary',fontSize: '0.8rem', pb:2 }}>
        {employee.position}
      </Typography>
      <ContactInfo icon={wp} text={employee.phone} />
      <ContactInfo icon={sms} text={employee.email} />
      {/*<Box sx={{ display: 'flex', alignItems: 'center', pt: 1, justifyContent: 'center' }}>
        <IconButton aria-label="whatsapp">
          <a href={employee.whatsapp} target="_blank" rel="noopener noreferrer">
            <img src={wp} alt="whatsapp" width="32" height="32" />
          </a>
        </IconButton>
        <IconButton aria-label="telegram">
          <a href={employee.telegram} target="_blank" rel="noopener noreferrer">
            <img src={tg} alt="telegram" width="32" height="32" />
          </a>
        </IconButton>
        <IconButton aria-label="viber">
          <a href={employee.viber} target="_blank" rel="noopener noreferrer">
            <img src={vb} alt="viber" width="32" height="32" />
          </a>
        </IconButton>
      </Box>*/}
    </Box>
  </Card>
));

const ProfilePage = () => {
  return (
    <Box component="section" sx={{ p: 2 }}>
      <Typography component="div" variant="h5" sx={{ fontSize: '1.3rem', mb: 2, mt: 1  }}>
        Наша команда
      </Typography>

      {employees.map((employee, index) => (
        <EmployeeCard key={index} employee={employee} />
      ))}
    </Box>
  );
};

export default ProfilePage;
